import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import style from "./Users.module.css";
import { generatePageNumbers } from "./Pagination";
// import BASE_URL from "../Config";
const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log("BASE_URL",BASE_URL)

const DisplayUsersData = () => {
  const [allUserData, setAllUserData] = useState([]);
  // const [open, setOpen] = useState(false);
  const [openVisiIcon, setOpenVisiIcon] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [companyUser, setCompanyUser] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // For positioning the popover
  const [open, setOpen] = useState(Boolean(anchorEl));

  const navigate = useNavigate();
  const [status, setStatus] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  // const rowsPerPage = Math.ceil(allUserData.length / 10);
  const rowsPerPage = 10;
  let domain = localStorage.getItem("domain")
  const getUsersData = () => {
    axios
      .get(`${BASE_URL}/api/v1/user/allUsers/`+domain, {
        params: { search: searchQuery }, // Pass the search query as a parameter
      })
      .then((res) => {
        const rejected_pending_Users = res.data?.filter(
          (user) =>  user.status === "Rejected" || user.status === "Pending"
        );
        setAllUserData(rejected_pending_Users);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return allUserData.slice(startIndex, endIndex);
  };
  const [userData, setUserData] = useState(getCurrentPageData());

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setUserData(getCurrentPageData());
  }, [page, allUserData]);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(allUserData.length / rowsPerPage) - 1)
    );
  };

  useEffect(() => {
    getUsersData();
  }, [searchQuery]);

  const getCompanyUsers = (user) => {
    const domain = user?.domain;
    axios
      .get(`${BASE_URL}/api/v1/user/getCompanyUsers/` + domain)
      .then((res) => {
        setCompanyUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClickOpen = (user, event) => {
    getCompanyUsers(user);
    setCurrentUser(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClickOpenVisiIcon = (user) => {
    setOpenVisiIcon(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleCloseVisiIcon = () => {
    setOpenVisiIcon(false);
  };

  useEffect(() => {
    Object.keys(status).forEach((email) => {
      axios
        .put(`${BASE_URL}/api/v1/user/updateStatus`, {
          email: email,
          status: status[email],
        })
        .then((res) => {
          // alert(res.data.message);
          getUsersData();
        })
        .catch((error) => {
          // alert(error.response.data);
        });
    });
  }, [status]);

  const handleApprove = async (user) => {
    let domain = user.domain;
    setStatus((prevStatus) => ({
      ...prevStatus,
      [user.email]: { status: "Approved", disabled: true, domain: domain },
    }));
  };

  const handleReject = async (user) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [user.email]: { status: "Rejected", disabled: true },
    }));
  };

  const handleRejectDelete = (user) => {
    const id = user._id;
    const email = user.email;
    axios
      .delete(`${BASE_URL}/api/v1/user/deletedUsers/` + id, {
        data: { email },
      })
      .then((res) => {
        alert("Request Deleted");
        //  setActionsDisabled(true)
        getUsersData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };


  // console.log("CompanyUser",companyUser)
  return (
    <Box className={style.usersDataCard}>
      <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ padding: "20px" }}
        >
          New Requests
        </Typography>
        <Divider />
        <Box height={10} />
        <Stack direction="row" spacing={2} className="my-2 mb-2">
          <Box>
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: "500px" }}
            />
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button
            variant="contained"
            endIcon={<AddCircleIcon />}
            onClick={() => {
              navigate("/singleusersignup");
            }}
          >
            Add
          </Button>
        </Stack>
        <Box height={10} />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" sx={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              margin: "auto",
              marginTop: "40px",
              textAlign: "center",
            }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Name
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Domain
                </TableCell>
                {/* <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Domain Type
                </TableCell> */}
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Email
                </TableCell>

                <TableCell align="left" style={{ minWidth: "103px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Date
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Status
                </TableCell>
                <TableCell align="center" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Actions
                </TableCell>
                <TableCell align="center" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((data, i) => {
                return (
                  <TableRow key={i} sx={{
                    backgroundColor: i % 2 === 1 ? "#8caacf" : "transparent",
                  }}>
                    <TableCell align="left">{(data?.firstName)+" "+(data?.lastName)}</TableCell>
                    <TableCell align="left">{data?.domain}</TableCell>
                    {/* <TableCell align="left">{data?.domainType}</TableCell> */}
                    <TableCell align="left">{data?.email}</TableCell>
                    <TableCell align="left">{formatDate(data?.createdAt)}</TableCell>
                    <TableCell align="left">
                      {data.status === "Pending" ||
                      data.status === "Rejected" ? (
                        <span
                          style={{
                            color: data.status === "Pending" ? "blue" : "red",
                          }}
                        >
                          {data.status}
                        </span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={(e) => handleClickOpen(data, e)}
                        size="small"
                      >
                        <MoreVertIcon
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      </IconButton>
                    </TableCell>

                    {/* Display the action status */}
                    <TableCell align="left">
                      {status[data.email] === "Approved" ? (
                        <span style={{ color: "green" }}></span>
                      ) : status[data.email] === "Rejected" ? (
                        <span style={{ color: "red" }}></span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            onClick={handlePreviousPage}
            variant="outlined"
            color="primary"
            disabled={page === 0}
          >
            Previous
          </Button>
          {generatePageNumbers(
            Math.ceil(allUserData.length / rowsPerPage),
            page
          ).map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber - 1)}
              variant={pageNumber - 1 === page ? "contained" : "outlined"}
              color="primary"
              sx={{ margin: "0 2px" }}
            >
              {pageNumber}
            </Button>
          ))}
          <Button
            onClick={handleNextPage}
            variant="outlined"
            color="primary"
            disabled={page === Math.ceil(allUserData.length / rowsPerPage) - 1}
          >
            Next
          </Button>
        </Box>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => {handleApprove(currentUser); handleClose()}} style={{color:"green"}}>Approve</MenuItem>
        <MenuItem onClick={() => {handleReject(currentUser);handleClose()}} style={{color:"brown"}}>Reject</MenuItem>
        <MenuItem onClick={() => {handleRejectDelete(currentUser);handleClose()}} style={{color:"red"}}>
          Reject/Delete
        </MenuItem>
        <MenuItem onClick={() => {handleClickOpenVisiIcon(currentUser); handleClose()}} style={{color:"black"}}>
          View Users
        </MenuItem>
        {/* <DialogActions>
          <Button onClick={handleClose}  style={{color:"black"}}>
            x
          </Button>
        </DialogActions> */}
      </Menu>

      <Dialog
        open={openVisiIcon}
        onClose={handleCloseVisiIcon}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"User Details"}</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                <TableCell align="left" style={{ minWidth: "100px" }}>
                  First Name
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px" }}>
                  Last Name
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px" }}>
                  Email
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px" }}>
                  Mobile
                </TableCell>
                 <TableCell align="left" style={{ minWidth: "100px" }}>
                  Role
                </TableCell>
                {/* <TableCell align="left" style={{ minWidth: "100px" }}>
                  Role
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px" }}>
                  Created At
                </TableCell> */}
              </TableRow>
              <TableBody>
                {companyUser?.map((data, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="left">{data.firstName}</TableCell>
                      <TableCell align="left">{data.lastName}</TableCell>
                      <TableCell align="left">{data.email}</TableCell>
                      <TableCell align="left">{data.mobile}</TableCell>
                      <TableCell align="left">{data.role}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVisiIcon} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DisplayUsersData;
