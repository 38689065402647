import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Stack,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Navbar from "../../components/Navbar"
import SideNav from "../../components/Sidenav"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Signup.module.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PeopleIcon from "@mui/icons-material/People";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const initData = {
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  password: "",
};

const SingleUserSignup = () => {
  const getCurrentDomain = () => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.host;
  };
  const domain = getCurrentDomain();
  const [userData, setUserData] = useState({
    initData,
    isValidPassword: false,
  });
  const { firstName, lastName, mobile, email, password } = userData;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [signupEmailError, setSignupEmailError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });

    if (value === "") {
      if (name === "password") {
        setUserData((prevData) => ({ ...prevData, isValidPassword: false }));
      }
    } else if (name === "password") {
      const isValidPassword =
        value.length >= 8 &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        );

      // Update the userData object with the password validation result
      setUserData((prevData) => ({ ...prevData, isValidPassword }));
    }
    if (value === "") {
      if (name === "email") {
        setSignupEmailError(false);
      }
    } else if (name === "email") {
       // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; 
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.(com|net|org|edu|gov|in|mil|ca|uk|au|gov\.uk|co\.jp)$/i;
      const inputValue = e.target.value;

      if (!emailRegex.test(inputValue)) {
        setSignupEmailError(true);
      } else {
        setSignupEmailError(false);
      }

      setUserData((prevData) => ({ ...prevData, inputValue }));
    }

    if (value === "") {
      if (name === "mobile") {
        setMobileError(false);
      }
    } else if (name === "mobile") {
      const phoneRegex = /^\d{10}$/; // Example: 10-digit phone number
      const isValidMobile = phoneRegex.test(value);
      setMobileError(!isValidMobile);
      setUserData((prevData) => ({ ...prevData, mobile: value }));
    }
  };

  function handleSubmit(event) {
    console.log("Triggered");
    event.preventDefault();
    if (signupEmailError) {
      setDialogMessage("Please Enter Valid Email");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    if (mobileError) {
      setDialogMessage("Please Enter Valid Mobile Number");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/v1/user/register`, {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        domain: domain,
        email: email,
        password: password,
        captcha:false,
      })
      .then((res) => {
        console.log(res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
          navigate(`/signupRequest`);
        }, 3000);
        setUserData(initData);
      })
      .catch((error) => {
        console.log("error", error);
        setDialogMessage(error?.response?.data);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        // setUserData(initData);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
       <Navbar />
      <Box sx={{ display: "flex" }}>
        <SideNav/>
        {((signupEmailError && mobileError && (password && password.length > 0 && !userData.isValidPassword)) || (password && password.length > 0 && !userData.isValidPassword))?( <form
          className={styles.singleSignup}
          onSubmit={handleSubmit}
          action={<Link to="/login" />}
        >
          <h2>Add User</h2>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 3 }}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="First Name"
              onChange={handleChange}
              name="firstName"
              value={firstName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Last Name"
              onChange={handleChange}
              name="lastName"
              value={lastName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <TextField
            type="mobile"
            variant="outlined"
            color="secondary"
            label="Mobile"
            onChange={handleChange}
            value={mobile}
            name="mobile"
            fullWidth
            required
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            error={mobileError}
            helperText={mobileError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid mobile number</p> : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="email"
            variant="outlined"
            color="secondary"
            label="Email"
            onChange={handleChange}
            value={email}
            name="email"
            fullWidth
            required
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            inputProps={{ autoComplete: "new-email" }}
            error={signupEmailError}
            helperText={
              signupEmailError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid email address</p> : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            type={showPassword ? "text" : "password"}
            variant="outlined"
            color="secondary"
            label="Password"
            onChange={handleChange}
            value={password}
            name="password"
            required
            fullWidth
            sx={{ mb: 8.5, backgroundColor: "rgb(250, 245, 245)" }}
            error={password && password.length > 0 && !userData.isValidPassword} // Highlight if password is not valid
            helperText={
              password && password.length > 0 && !userData.isValidPassword ? (
                <div style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"4.5vw"}}>
                  Password must be at least 8 characters long and contain a combination of:
                  <br />
                  - Uppercase and lowercase letters
                  - At least one numeric digit (0-9)
                  <br />- At least one special character
                </div>
              ) : null
            }
            InputProps={{
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          {/* <ReCAPTCHA
          sitekey={Site_Key}
          onChange={handleRecaptchaChange}
        /> */}
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            disabled={loading}
            style={{
              backgroundColor: "#1A1A57",
              color: "white",
              height: "3vw",
            }}
          >
            {loading ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              " Register"
            )}
          </Button>
        </form>):( <form
          className={styles.singleSignup1}
          onSubmit={handleSubmit}
          action={<Link to="/login" />}
        >
          <h2>Add User</h2>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="First Name"
              onChange={handleChange}
              name="firstName"
              value={firstName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Last Name"
              onChange={handleChange}
              name="lastName"
              value={lastName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <TextField
            type="mobile"
            variant="outlined"
            color="secondary"
            label="Mobile"
            onChange={handleChange}
            value={mobile}
            name="mobile"
            fullWidth
            required
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            error={mobileError}
            helperText={mobileError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid mobile number</p> : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="email"
            variant="outlined"
            color="secondary"
            label="Email"
            onChange={handleChange}
            value={email}
            name="email"
            fullWidth
            required
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            inputProps={{ autoComplete: "new-email" }}
            error={signupEmailError}
            helperText={
              signupEmailError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid email address</p> : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            type={showPassword ? "text" : "password"}
            variant="outlined"
            color="secondary"
            label="Password"
            onChange={handleChange}
            value={password}
            name="password"
            required
            fullWidth
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            error={password && password.length > 0 && !userData.isValidPassword} // Highlight if password is not valid
            helperText={
              password && password.length > 0 && !userData.isValidPassword ? (
                <div style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"4.5vw"}}>
                  Password must be at least 8 characters long and contain a combination of:
                  <br />
                  - Uppercase and lowercase letters
                  - At least one numeric digit (0-9)
                  <br />- At least one special character
                </div>
              ) : null
            }
            InputProps={{
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          {/* <ReCAPTCHA
          sitekey={Site_Key}
          onChange={handleRecaptchaChange}
        /> */}
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            disabled={loading}
            style={{
              backgroundColor: "#1A1A57",
              color: "white",
              height: "3vw",
            }}
          >
            {loading ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              " Register"
            )}
          </Button>
        </form>)}
      </Box>
      {/* SignUp Dialog Box */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SingleUserSignup;
