import { Box } from '@mui/material'
import React from 'react'
import Navbar from '../../components/Navbar'
import Sidenav from '../../components/Sidenav'
import UsersNav from './UsersNav'
import DisplayApprovedUsers from './DisplayApprovedUsers'

const Users = () => {
  return (
    <Box>
         <Navbar/>
        <Box sx={{ display: "flex" }}>
            <Sidenav />
            </Box>
            <Box >
        <h1>Users</h1>
        <UsersNav/>
        </Box>
        <DisplayApprovedUsers/>
    </Box>
  )
}

export default Users