import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import Navbar from "../../components/Navbar";
import Sidenav from "../../components/Sidenav";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

// import BASE_URL from "../Config";
const  BASE_URL = process.env.REACT_APP_BASE_URL;
console.log("HomeBaseUrl", BASE_URL)
const Home = () => {
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [candidates, setCandidates] = useState([]);
const domain = localStorage.getItem("domain")
  const getApprovedUsers = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/user/allUsers/`+domain
      );
      const Approved_Users = res.data?.filter(
        (user) => user.status === "Approved"
      );
      setApprovedUsers(Approved_Users);
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingUsers = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/user/allUsers/`+domain);
      const Pending_Users = res.data?.filter(
        (user) => user.status === "Pending"
      );
      setPendingUsers(Pending_Users);
    } catch (error) {
      console.log(error);
    }
  };

  
  const getCandidatesData = () => {
    axios
      .get(`${BASE_URL}/api/v1/user/getCandidates/`+domain)
      .then((res) => {
        setCandidates(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getApprovedUsers();
    getPendingUsers()
    // getUserData();
    getCandidatesData()
  }, []);

  // console.log("approvedUsers.length",approvedUsers.length)
  localStorage.setItem("approvedUsers",approvedUsers.length)
  localStorage.setItem("pendingUsers",pendingUsers.length)
  localStorage.setItem("candidates",candidates.length)
 

  let activeUsers = parseInt(localStorage.getItem("approvedUsers"),10) || 0;
  let candidateUsers = parseInt(localStorage.getItem("candidates"),10) || 0;
  const pending_Users = parseInt(localStorage.getItem("pendingUsers"), 10) || 0;
 
  // Data for the bar chart
  const chartData = [
    { name: "Active Users", count: activeUsers },
    { name: "Pending Users", count: pending_Users },
    { name: "Total Candidates", count: candidateUsers },
  ];
// console.log("activeUsers",activeUsers)
  return (
    <>
      <Navbar />
      <Grid container spacing={2}>
        <Sidenav />
        <Grid item xs={12} md={8} marginLeft={10} marginTop={10}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Active/Pending Users and Total Candidates
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} marginLeft={50}>
          <Card sx={{backgroundColor:"greenyellow",marginBottom:"5px"}} >
            <CardContent>
              <Typography variant="h6">Active Users</Typography>
              <Typography variant="h4">{activeUsers}</Typography>
            </CardContent>
          </Card>
          <Card sx={{backgroundColor:"white",marginBottom:"5px"}}>
            <CardContent>
              <Typography variant="h6">Pending Users</Typography>
              <Typography variant="h4">{pending_Users}</Typography>
            </CardContent>
          </Card >
          <Card sx={{backgroundColor:"cyan",marginBottom:"20px"}}>
            <CardContent>
              <Typography variant="h6">Candidates</Typography>
              <Typography variant="h4">{candidateUsers}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
