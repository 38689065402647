import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { generatePageNumbers } from "../Users/Pagination";
import { useParams } from "react-router-dom";
import PopupForm from "./PopupForm";

const DisplayCandidates = ({ candidates,getUserData }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [displayedCandidates, setDisplayedCandidates] = useState([]);
  const [page, setPage] = useState(0);
  // const rowsPerPage = Math.ceil(displayedCandidates.length / 10);
  const rowsPerPage = 10;

  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return displayedCandidates?.slice(startIndex, endIndex);
  };
  const [candidateData, setCandidateData] = useState(getCurrentPageData());

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setCandidateData(getCurrentPageData());
  }, [page, displayedCandidates]);

  useEffect(() => {
    setDisplayedCandidates(candidates);
    // getUserData()
  }, [candidates]);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(displayedCandidates?.length / rowsPerPage) - 1
      )
    );
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    getUserData()
  };

// console.log("candidateData",candidateData)
  return (
    <Box>
      <Paper
        sx={{
          width: "75%",
          overflow: "hidden",
          padding: "12px",
          marginLeft: "260px",
          marginTop:"120px"
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ padding: "20px" }}
        >
          Scheduled Interview
        </Typography>
        <Box sx={{position:"absolute",right:"80px",top:"150px"}}>
        <Button variant="outlined" color="primary" onClick={openPopup}>
        ADD Interview
      </Button>
      </Box>
      <PopupForm open={isPopupOpen} onClose={closePopup} />
        <Divider />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" sx={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              margin: "auto",
              // marginTop: "50px",
              textAlign: "center",
            }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Name
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Email
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}> 
                  Expertise
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Domain
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Interview Link
                </TableCell>
                {/* <TableCell align="center" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Time
                </TableCell>
                <TableCell align="center" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Date
                </TableCell>
                <TableCell align="left" style={{ minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white", }}>
                  Status
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {candidateData?.map((data, i) => {
                return (
                  <TableRow key={i} sx={{
                    backgroundColor: i % 2 === 1 ? "#8caacf" : "transparent",
                  }}>
                    <TableCell align="left">{data?.name}</TableCell>
                    <TableCell align="left">{data?.email}</TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      {data?.techStack && data?.techStack.join(", ")}
                    </TableCell>
                    <TableCell align="left">{data?.domain}</TableCell>
                    <TableCell align="left">{data?.interviewLink}</TableCell>
                    {/* <TableCell align="left">{data?.startTime} - {data?.toTime}</TableCell>
                    <TableCell align="left">{data?.date}</TableCell>
                    <TableCell
                      style={{
                        color:
                          data.status === "Pending"
                            ? "blue"
                            : data.status === "Expired"
                            ? "red"
                            : data.status === "Completed"
                            ? "green"
                            : "black",
                      }}
                      align="left"
                    >
                      {data?.status}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            onClick={handlePreviousPage}
            variant="outlined"
            color="primary"
            disabled={page === 0}
          >
            Previous
          </Button>
          {generatePageNumbers(
            Math.ceil(displayedCandidates?.length / rowsPerPage),
            page
          ).map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber - 1)}
              variant={pageNumber - 1 === page ? "contained" : "outlined"}
              color="primary"
              sx={{ margin: "0 2px" }}
            >
              {pageNumber}
            </Button>
          ))}
          <Button
            onClick={handleNextPage}
            variant="outlined"
            color="primary"
            disabled={
              page === Math.ceil(displayedCandidates?.length / rowsPerPage) - 1
            }
          >
            Next
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default DisplayCandidates;
