import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../appStore";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatIcon from "@mui/icons-material/Chat";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import SendIcon from "@mui/icons-material/Send";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidenav() {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const open = useAppStore((state) => state.dopen);
  const [candidatesOpen, setCandidatesOpen] = React.useState(false);
  const [configurationsOpen, setConfigurationsOpen] = React.useState(false);


  // Function to toggle the "Configurations" submenu
  const toggleConfigurationsMenu = () => {
    setConfigurationsOpen(!configurationsOpen);
  };

  const navigateCandidate = () => {
    navigate("/inviteCandidate");
    setCandidatesOpen(!candidatesOpen);
  };

  const handleInviteCandidate = () => {
    navigate("/inviteCandidate");
    setCandidatesOpen(true);
  };
  const handleResults = () => {
    navigate("/interviewResult");
    setCandidatesOpen(true);
  };
  console.log("candidatesOpen",candidatesOpen)
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        {/* <IconButton onClick={() => setOpen(!open)}>
         <MenuIcon />
        </IconButton> */}
      </DrawerHeader>
      <List>
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Insights" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>


        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/usersData");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {/* Candidates */}
        <ListItem disablePadding sx={{ display: "block" }}  >
          <ListItemButton
            onClick={navigateCandidate}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="Candidates" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {/* Nested Branding Option (Conditionally Rendered) */}
        {candidatesOpen &&
          open && ( // Only render when "Candidates" menu is open
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={handleInviteCandidate}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
                  <SendIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Invite Candidate"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          )}
        {candidatesOpen && open && (
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={handleResults}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  marginLeft: "20px",
                }}
              >
                <DataUsageIcon />
              </ListItemIcon>
              <ListItemText primary="Results" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        )}

        {/* Configurations */}
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={toggleConfigurationsMenu}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Configurations"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        {/* Nested Branding Option (Conditionally Rendered) */}
        {configurationsOpen &&
          open && ( // Only render when "Configurations" menu is open
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              // onClick={() => {
              //   navigate("/branding"); // Update the route path accordingly
              // }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* <AccountBalanceIcon /> Use the new icon */}
                </ListItemIcon>
                <ListItemText
                  primary="Niyukti"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          )}
        {configurationsOpen &&
          open && ( // Only render when "Configurations" menu is open
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              // onClick={() => {
              //   navigate("/branding"); // Update the route path accordingly
              // }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* <AccountBalanceIcon /> Use the new icon */}
                </ListItemIcon>
                <ListItemText
                  primary="Spiderweb"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          )}

      </List>
    </Drawer>
  );
}
