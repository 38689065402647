import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import style from "./Users.module.css";
import { generatePageNumbers } from "./Pagination";
// import BASE_URL from "../Config";
const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log("Base_url",BASE_URL)


const DisplayApprovedUsers = () => {
  const [allUserData, setAllUserData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [page, setPage] = useState(0);
  const [openVisiIcon, setOpenVisiIcon] = useState(false);
  const rowsPerPage = 10;
  let domain = localStorage.getItem("domain");

  

  const getAllUsers = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/v1/user/allUsers/` + domain
      );
      const Users = res?.data;
      const approvedUsers = Users?.filter((user) => user.status === "Approved");
      setAllUserData(approvedUsers)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsers();
    // getCompanyUsers();
  }, []);

  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return allUserData.slice(startIndex, endIndex);
  };

  const [userData, setUserData] = useState(getCurrentPageData());

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setUserData(getCurrentPageData());
  }, [page, allUserData]);

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const handleSwitchToggle = (user) => {
    const newStatus = !user.userEnabled;

    axios
      .put(`${BASE_URL}/api/v1/user/userEnabled`, {
        email: user.email,
        userEnabled: newStatus,
        domain: user.domain,
      })
      .then((res) => {
        console.log("Status updated successfully");
        setAllUserData((prevUserData) =>
          prevUserData.map((u) =>
            u.email === user.email ? { ...u, userEnabled: newStatus } : u
          )
        );
        // getCompanyUsers();
        getAllUsers();
      })
      .catch((error) => {
        console.log("Error updating status:", error);
      });
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(allUserData.length / rowsPerPage) - 1)
    );
  };

  const handleClickOpenVisiIcon = (user) => {
    setCurrentUser(user);
    // getCompanyUsers(user);
    setOpenVisiIcon(true);
  };
  const handleCloseVisiIcon = () => {
    setOpenVisiIcon(false);
  };

  // console.log("approved",fetchedUsers)
  return (
    <Box className={style.usersDataCard}>
      <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ padding: "20px" }}
        >
          Users Data
        </Typography>
        <Divider />
        <Box height={10} />

        <Box height={10} />
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              margin: "auto",
              // marginTop: "50px",
              textAlign: "center",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  First Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Last Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Company Domain
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Enable/Disable
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  User Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData?.map((data, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: i % 2 === 1 ? "#8caacf" : "transparent",
                    }}
                  >
                    <TableCell align="left">{data.firstName}</TableCell>
                    <TableCell align="left">{data.lastName}</TableCell>
                    <TableCell align="left">{data.domain}</TableCell>
                    <TableCell align="left">{data.email}</TableCell>
                    <TableCell align="left">
                      <Switch
                        checked={data.userEnabled}
                        onChange={() => handleSwitchToggle(data)}
                        color="primary"
                      />
                    </TableCell>

                    <TableCell align="center">
                      <VisibilityIcon
                        style={{
                          fontSize: "20px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleClickOpenVisiIcon(data);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            onClick={handlePreviousPage}
            variant="outlined"
            color="primary"
            disabled={page === 0}
          >
            Previous
          </Button>
          {generatePageNumbers(
            Math.ceil(allUserData.length / rowsPerPage),
            page
          ).map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber - 1)}
              variant={pageNumber - 1 === page ? "contained" : "outlined"}
              color="primary"
              sx={{ margin: "0 2px" }}
            >
              {pageNumber}
            </Button>
          ))}
          <Button
            onClick={handleNextPage}
            variant="outlined"
            color="primary"
            disabled={page === Math.ceil(allUserData.length / rowsPerPage) - 1}
          >
            Next
          </Button>
        </Box>
      </Paper>

      <Dialog
        open={openVisiIcon}
        onClose={handleCloseVisiIcon}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Company Users"}</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: "80px" }}>
                    First Name
                  </TableCell>
                  <TableCell align="left" style={{ width: "80px" }}>
                    Last Name
                  </TableCell>
                  <TableCell align="left" style={{ width: "80px" }}>
                    Email
                  </TableCell>
                  <TableCell align="left" style={{ width: "80px" }}>
                    Mobile
                  </TableCell>
                  <TableCell align="left" style={{ width: "80px" }}>
                    Domain Name
                  </TableCell>
                  <TableCell align="left" style={{ width: "80px" }}>
                    Role
                  </TableCell>
                  <TableCell align="left" style={{ width: "80px" }}>
                    Created At
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* Display Users of Particular Company */}
              <TableBody>
                <TableRow>
                  <TableCell align="left">{currentUser?.firstName}</TableCell>
                  <TableCell align="left">{currentUser?.lastName}</TableCell>
                  <TableCell align="left">{currentUser?.email}</TableCell>
                  <TableCell align="left">{currentUser?.mobile}</TableCell>
                  <TableCell align="left">{currentUser?.domain}</TableCell>
                  <TableCell align="left">{currentUser?.role}</TableCell>
                  <TableCell align="left">{formatDate(currentUser?.createdAt)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVisiIcon} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DisplayApprovedUsers;

