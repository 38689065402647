export const generatePageNumbers = (totalPages, currentPage) => {
    const pageNeighbours = 2;
    const totalNumbers = pageNeighbours * 2 + 1;
    const totalBlocks = totalNumbers + 2;

    if (totalPages <= totalBlocks) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);

      let pages = Array.from(
        { length: totalNumbers },
        (_, index) => index + startPage
      );

      const hasLeftSpill = startPage > 1;
      const hasRightSpill = totalPages - endPage > 0;
      const spillOffset = totalNumbers - (endPage - startPage);

      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = pages.slice(0, spillOffset);
        pages = [1, "...", ...extraPages, totalPages];
      } else if (!hasLeftSpill && hasRightSpill) {
        const extraPages = pages.slice(-spillOffset);
        pages = [1, ...extraPages, "...", totalPages];
      } else if (hasLeftSpill && hasRightSpill) {
        pages = [1, "...", ...pages, "...", totalPages];
      }

      return pages;
    }
  };