import React, { useEffect, useState } from "react";
import { TextField, Button, Box, Alert, Dialog, DialogContent, DialogContentText, DialogActions, CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { FaLinkedin } from 'react-icons/fa';
import axios from "axios";
import styles from "./Signin.module.css";
import image from "../../assets/Niyukti/Image.png";
import niyuktiLogo from "../../assets/Niyukti/niyuktiLogo.png";
import arrow from "../../assets/Niyukti/arrow.png";
import line from "../../assets/Niyukti/Line.png";
import orImage from "../../assets/Niyukti/orImage.png";
import emailIcon from "../../assets/Niyukti/emailIcon.png";
import passwordIcon from "../../assets/Niyukti/passwordIcon.png";
import "../Variables.css";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { textData } from "../../assets/Niyukti/Text";
import ForgotPasswordComponent from "../ForgotPassword/ForgotPasswordComponent";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Domain = process.env.REACT_APP_Domain;
const Domain1 = process.env.REACT_APP_Domain1;
console.log("BASE_URL",BASE_URL)
console.log("Domain ",Domain)
const buttonStyle = {
  color: "#FFF",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  backgroundColor: "var(--primary-color)",
  lineHeight: "normal",
  fontFamily: "Poppins",
  borderRadius: "25px",
  width: "170px",
  // marginTop:"20px"
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [signinEmailError, setSigninEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { email, password } = userData;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const getCurrentDomain = () => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.host;
  };
  const domain = getCurrentDomain();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    if(value===""){
      if (name === "email") {
        setSigninEmailError(false);
      }
    }else if (name === "email") {
      // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; 
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.(com|net|org|edu|gov|in|mil|ca|uk|au|gov\.uk|co\.jp)$/i;
      const inputValue = e.target.value;

      if (!emailRegex.test(inputValue)) {
        setSigninEmailError(true);
      } else {
        setSigninEmailError(false);
      }

      setUserData((prevData) => ({ ...prevData, inputValue }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setEmailError(false);
    setPasswordError(false);

    if (userData.email === "") {
      setEmailError(true);
      return;
    }
    if (userData.password === "") {
      setPasswordError(true);
      return;
    }
    if (signinEmailError) {
      setDialogMessage("Please Enter Valid Email");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/v1/user/login`, {
        userData,
        domain: domain,
      });

      const {
        data: { token, domain: responseDomain },
      } = response;
      const Domain = response?.data?.data?.domain;
      const Email = response?.data?.data?.email;
      const id = response?.data?.data?._id;
      localStorage.setItem("id", id);
      localStorage.setItem("email", Email);
      localStorage.setItem("token", token);
      localStorage.setItem("domain", Domain);

      // if(response.data?.data?.role==="user"){
      //   return window.location.href = `http://${responseDomain}/`;
      // }
      console.log("res".response)
      setDialogMessage(response?.data?.message);
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
        navigate("/");
      }, 2000);
      setUserData({
        email: "",
        password: "",
      });
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        setDialogMessage(errorMessage);
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 2000);
    }
      setUserData({
        email: "",
        password: "",
      });
    }finally {
      setLoading(false);
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/niyukti-jobs-2b8a6929b/', '_blank');
  };

  return (
    <React.Fragment>
      {(domain === Domain || domain === Domain1) ? (
        <Box className={styles.formContainer}>
          <Box sx={{ width: "50%" }}>
            <img className={styles.logo} src={niyuktiLogo} alt="logo" />
            <img
              className={styles.leftImage}
              src={image}
              alt="Left_side_image"
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box className={styles.containerBox}>
              <Box className={styles.getStartedText}>
                <p>Get Started</p>
              </Box>
              <Box className={styles.welcomeText}>
                <p>Welcome to {textData.name}!</p>
              </Box>
              <Box className={styles.managementText}>
                <p>The Human Capital management System. </p>
              </Box>
            </Box>
            <Box className={styles.signinContainer}>
              <form
                // height="10px"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <h2 className={styles.signInText}>Sign In</h2>
                <TextField
                  label="Email"
                  onChange={handleChange}
                  required
                  variant="outlined"
                  color="secondary"
                  type="email"
                  sx={{
                    mt: "-12px",
                    mb: 4.5,
                    backgroundColor: "rgb(250, 245, 245)",
                  }}
                  fullWidth
                  name="email"
                  value={email}
                  error={emailError || signinEmailError}
                helperText={
                  signinEmailError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"7vw"}}>Please enter a valid email address</p> : ""
                }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={emailIcon} alt="email icon" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Password"
                  onChange={handleChange}
                  required
                  variant="outlined"
                  color="secondary"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  name="password"
                  error={passwordError}
                  fullWidth
                  sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={passwordIcon} alt="passwordIcon" />
                      </InputAdornment>
                    ),
                    // Add this endAdornment section
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ display: "flex", margin: "auto" }}>
                  <Button  disabled={loading} style={buttonStyle} type="submit">
                  {loading ? (
                    <CircularProgress size={24} /> // Show loader when loading is true
                  ) : (
                    " Sign In"
                  )}
                  </Button>
                  {/* <img className={styles.arrowImage} src={arrow} alt="arrow" /> */}
                  <Box className={styles.rightBox}>
                    <p>
                      <a className={styles.signupLink} href="#">
                        Forgot your password?
                      </a>
                    </p>
                     {/* <ForgotPasswordComponent/> */}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", margin: "auto" }}>
                  <img className={styles.lineImage} src={line} alt="line" />
                  <img className={styles.orImage} src={orImage} alt="or" />
                </Box>
                <Box sx={{ mt: "50px",display:"flex",justifyContent:"space-between" }}>
                  <p className={styles.signupText}>
                    Don't have an account?{" "}
                    <a className={styles.signupLink} href="/register">
                      Sign Up
                    </a>
                  </p>
                  <FaLinkedin
            className={styles.linkedinIcon} // Add styling as needed
            onClick={handleLinkedInClick}
          />
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={styles.formContainer}>
          <Box sx={{ width: "50%" }}>
            <img className={styles.logo} src={niyuktiLogo} alt="logo" />
            <img
              className={styles.leftImage}
              src={image}
              alt="Left_side_image"
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box className={styles.containerBox}>
              <Box className={styles.getStartedText}>
                <p>Get Started</p>
              </Box>
              <Box className={styles.welcomeText}>
                <p>Welcome to Niyukti!</p>
              </Box>
              <Box className={styles.managementText}>
                <p>The Human Capital management System. </p>
              </Box>
            </Box>
            <Box className={styles.signinContainer}>
              <form
                // height="10px"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <h2 className={styles.signInText}>Sign In</h2>
                <TextField
                  label="Email"
                  onChange={handleChange}
                  required
                  variant="outlined"
                  color="secondary"
                  type="email"
                  sx={{
                    mt: "-12px",
                    mb: 4.5,
                    backgroundColor: "rgb(250, 245, 245)",
                  }}
                  fullWidth
                  name="email"
                  value={email}
                  error={emailError || signinEmailError}
                helperText={
                  signinEmailError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"7vw"}}>Please enter a valid email address</p> : ""
                }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={emailIcon} alt="email icon" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Password"
                  onChange={handleChange}
                  required
                  variant="outlined"
                  color="secondary"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  name="password"
                  error={passwordError}
                  fullWidth
                  sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={passwordIcon} alt="passwordIcon" />
                      </InputAdornment>
                    ),
                    // Add this endAdornment section
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ display: "flex", margin: "auto" }}>
                <Button  disabled={loading} style={buttonStyle} type="submit">
                  {loading ? (
                    <CircularProgress size={24} /> // Show loader when loading is true
                  ) : (
                    " Sign In"
                  )}
                  </Button>
                  {/* <img className={styles.arrowImage} src={arrow} alt="arrow" /> */}
                  {/* <Box className={styles.rightBox}>
                    <p>
                      <a className={styles.signupLink} href="#">
                        Forgot your password?
                      </a>
                    </p>
                  </Box> */}
                  <Box className={styles.rightBox}>
                    <p>
                      <a className={styles.signupLink} href="#">
                        Forgot your password?
                      </a>
                    </p>
                     {/* <ForgotPasswordComponent/> */}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", margin: "auto" }}>
                  <img className={styles.lineImage} src={line} alt="line" />
                  <img className={styles.orImage} src={orImage} alt="or" />
                </Box>
                <Box sx={{ mt: "50px",display:"flex",justifyContent:"space-between" }}>
                  <p className={styles.signupText}>
                    Don't have an account?{" "}
                    <a className={styles.signupLink} href="/companyusersignup">
                      Sign Up
                    </a>
                  </p>
                  <FaLinkedin
            className={styles.linkedinIcon} // Add styling as needed
            onClick={handleLinkedInClick}
          />
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      )}

       {/* Dialog for alert message of Signin */}
       <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto", // Set a minimum height to center vertically
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Login;
