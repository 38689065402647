import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Stack,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import styles from "./Candidate.module.css";
import DisplayCandidates from "./DisplayCandidates";
import { useGetUserData } from "./GetData";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const initData = {
  name: "",
  email: "",
  techStack: [],
  experience: []
};
const CandidateForm = ({ onClose }) => {
  const getCurrentDomain = () => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.host;
  };
  const domain = getCurrentDomain();
  const [candidateData, setCandidateData] = useState(initData);
  // const { name, email, techStack, experience, date, toTime } = candidateData;
  const { name, email, techStack, experience } = candidateData;
  const { candidates, getUserData } = useGetUserData();
  const [signupEmailError, setSignupEmailError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setCandidateData({ ...candidateData, [name]: value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidateData({ ...candidateData, [name]: value });
    if (name === "techStack") {
      const inputValue = e.target.value;
      setCandidateData((prevData) => ({ ...prevData, inputValue }));
    } else {
      if (value === "") {
        if (name === "email") {
          setSignupEmailError(false);
        }
      } else if (name === "email") {
         // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; 
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.(com|net|org|edu|gov|in|mil|ca|uk|au|gov\.uk|co\.jp)$/i;
        const inputValue = e.target.value;

        if (!emailRegex.test(inputValue)) {
          setSignupEmailError(true);
        } else {
          setSignupEmailError(false);
        }

        setCandidateData((prevData) => ({ ...prevData, inputValue }));
      }
    }
  };

  // console.log("candidateData",candidateData)

  function handleSubmit(event) {
    event.preventDefault();
    if (signupEmailError) {
      setDialogMessage("Please Enter Valid Email");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
     
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/v1/user/createCandidate`, {
        ...candidateData,
        domain: domain,
      })
      .then((res) => {
        // console.log(res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        setCandidateData(initData);
        getUserData();
        onClose();
      })
      .catch((error) => {
        console.log("error", error);
        setDialogMessage(error?.response?.data);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        // setCandidateData(initData);
        // onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const techStacks = [
    "Node",
    "React",
    "Angular",
    "SEO",
    "Digital Marketing",
    "Laravel",
    "Word Press",
    "UI/UX",
    "Flutter",
    "IOS",
    "android",
    "React Native",
    "Content Writer",
    "AI",
    "Python",
    "Vue",
  ];
  const Experience = ["0", "1", "2", "3", "4", "5", "6+"];
  console.log("userData", candidateData);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        {/* <Sidenav /> */}
        <form
          className={styles.candidateForm}
          onSubmit={handleSubmit}
          action={<Link to="/login" />}
        >
          {/* <h2>Schedule Interview</h2> */}

          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Name"
            onChange={handleChange}
            name="name"
            value={name}
            fullWidth
            required
            sx={{ mb: 2.5, backgroundColor: "rgb(250, 245, 245)", mt: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PeopleIcon />
                </InputAdornment>
              ),
            }}
          />

         {signupEmailError?( <TextField
            type="email"
            variant="outlined"
            color="secondary"
            label="Email"
            onChange={handleChange}
            value={email}
            name="email"
            fullWidth
            required
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            error={signupEmailError}
            helperText={
              signupEmailError ?<p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"18vw"}}>Please enter a valid email address</p>: ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
):( <TextField
  type="email"
  variant="outlined"
  color="secondary"
  label="Email"
  onChange={handleChange}
  value={email}
  name="email"
  fullWidth
  required
  sx={{ mb: 2.5, backgroundColor: "rgb(250, 245, 245)" }}
  error={signupEmailError}
  helperText={
    signupEmailError ?<p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid email address</p>: ""
  }
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <EmailIcon />
      </InputAdornment>
    ),
  }}
/>
)}
          <Autocomplete
            multiple
            id="tech-stacks"
            options={techStacks}
            getOptionLabel={(techStack) => techStack}
            value={techStack}
            onChange={(e, newValue) => {
              setCandidateData({ ...candidateData, techStack: newValue });
            }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Expertise"
                variant="outlined"
                color="secondary"
                // required
                fullWidth
                sx={{ mb: 2.5, backgroundColor: "rgb(250, 245, 245)" }}
              />
            )}
          />
          <TextField
            type="number"
            variant="outlined"
            color="secondary"
            label="Experience in years"
            onChange={handleChange}
            value={experience}
            name="experience"
            fullWidth
            required
            sx={{ mb: 2.5, backgroundColor: "rgb(250, 245, 245)" }}
            select // Add the "select" prop to make it a dropdown
          >
            {Experience.map((exp) => (
              <MenuItem key={exp} value={exp}>
                {exp}
              </MenuItem>
            ))}
          </TextField>

          {/* <Typography variant="h6" gutterBottom>
            Select Date and Time
          </Typography>
          <TextField
            id="date"
            label="Date"
            type="date"
            onChange={handleChange}
            value={date}
            name="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
            required
          />
          <TextField
            id="toTime"
            label="Time"
            type="time"
            onChange={handleChange}
            value={toTime}
            name="toTime"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min intervals
            }}
            fullWidth
            sx={{ mb: 4, mt: 2, backgroundColor: "rgb(250, 245, 245)" }}
            required
          /> */}
          <Button variant="outlined" color="secondary" type="submit" disabled={loading}>
          {loading ? (
                <CircularProgress size={24} /> // Show loader when loading is true
              ) : (
                "Schedule"
              )}
          </Button>
        </form>
        
        {/* Candidate Form Dialog Box */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </Box>

      {/* <DisplayCandidates candidates={candidates} /> */}
    </React.Fragment>
  );
};

export default CandidateForm;
