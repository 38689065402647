import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { generatePageNumbers } from "../pages/Users/Pagination";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function CandidatesList() {
  const [open, setOpen] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const getAllScores = async () => {
    const token = localStorage.getItem("token");
    const domain = localStorage.getItem("domain");
    // let userId = localStorage.getItem("id")
    // console.log("userId",userId)
    const headers = {
      Authorization: `${token}`,
    };
    // console.log("headers",headers)
    const response = await fetch(`${BASE_URL}/api/v1/getAllScores/` + domain, {
      headers: headers,
    });
    // console.log("res",response)
    const { scores } = await response.json();
    // console.log("scores",scores);
    setData(scores);
    setLoading(false);
  };

  const [page, setPage] = useState(0);
  // const rowsPerPage = Math.ceil(allUserData.length / 10);
  const rowsPerPage = 10;
  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data?.slice(startIndex, endIndex);
  };
  const [userData, setUserData] = useState(getCurrentPageData());

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    setUserData(getCurrentPageData());
  }, [page, data]);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(data?.length / rowsPerPage) - 1)
    );
  };

  useEffect(() => {
    getAllScores();
  }, []);
  const handleClickOpen = (candidate) => {
    setCurrentCandidate(candidate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function filterData(v) {
    // console.log("v",v)
    if (v) {
      setData([v]);
    } else {
      getAllScores();
    }
  }

  const formatDate = (timestamp) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const handleVideo = (user) => {
    console.log("User", user);
    const id = user?.interviewId;
    // const id = "1fdqbwjioz";
    console.log("id",id)
    axios
      .get(`${BASE_URL}/api/v1/user/getVideo/` + id)
      .then((res) => {
        // console.log("resVideo",res?.data)
        setVideoUrl(res?.data);
        setOpenVideoDialog(true); // Open the video dialog
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  return (
    <>
      <Paper
        sx={{
          width: "85%",
          marginLeft: "60px",
          overflow: "hidden",
          padding: "12px",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ padding: "20px" }}
        >
          Results
        </Typography>
        <Divider />
        <Box height={10} />
        <Stack direction="row" spacing={2} className="my-2 mb-2">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={data}
            sx={{ width: 300 }}
            onChange={(e, v) => filterData(v)}
            getOptionLabel={(data) => data?.candidateName || ""}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Select Candidates" />
            )}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {/* <Button
            variant="contained"
            endIcon={<AddCircleIcon />}
            onClick={() => {}}
          >
            Add
          </Button> */}
        </Stack>
        <Box height={10} />
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              margin: "auto",
              // marginTop: "50px",
              textAlign: "center",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Expertise
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Score
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Start Time-End Time
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    minWidth: "100px",
                    fontWeight: "bold",
                    backgroundColor: "#0468aa",
                    color: "white",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData?.map((data, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: i % 2 === 1 ? "#8caacf" : "transparent",
                    }}
                  >
                    <TableCell align="left">{data?.candidateName}</TableCell>
                    <TableCell align="left">
                      {data?.techStack.join(", ")}
                    </TableCell>
                    <TableCell align="left">{data?.score}</TableCell>
                    <TableCell align="left">
                      {formatDate(data?.interviewStartTime)} -{" "}
                      {formatDate(data?.interviewEndTime)}
                    </TableCell>
                    <TableCell align="left">
                      <VisibilityIcon
                        style={{
                          fontSize: "20px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleClickOpen(data);
                        }}
                      />
                      <VideoLibraryIcon
                        style={{
                          fontSize: "20px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleVideo(data);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            onClick={handlePreviousPage}
            variant="outlined"
            color="primary"
            disabled={page === 0}
          >
            Previous
          </Button>
          {generatePageNumbers(Math.ceil(data?.length / rowsPerPage), page).map(
            (pageNumber) => (
              <Button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber - 1)}
                variant={pageNumber - 1 === page ? "contained" : "outlined"}
                color="primary"
                sx={{ margin: "0 2px" }}
              >
                {pageNumber}
              </Button>
            )
          )}
          <Button
            onClick={handleNextPage}
            variant="outlined"
            color="primary"
            disabled={page === Math.ceil(data?.length / rowsPerPage) - 1}
          >
            Next
          </Button>
        </Box>
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Questions and Answers"}
        </DialogTitle>
        <DialogContent>
          {currentCandidate?.questionsAndAnswers.map((qa, i) => (
            <div key={i}>
              <DialogContentText id="alert-dialog-description">
                <b>Question: </b> {qa.question}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                <b>Answer: </b> {qa.answer}
              </DialogContentText>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Video Dialog */}
      <Dialog
        open={openVideoDialog}
        onClose={() => setOpenVideoDialog(false)} // Close the dialog when needed
        aria-labelledby="video-dialog-title"
        aria-describedby="video-dialog-description"
      >
        <DialogTitle id="video-dialog-title">Video</DialogTitle>
        <DialogContent>
          {/* Embed the video using an <iframe> */}
          {/* <iframe
            width="560"
            height="315"
            src={url}
            frameBorder="0"
            allowFullScreen
            title="Candidate Video"
          ></iframe> */}
          <Box>
          {videoUrl && (
        <video controls width="320" height="240">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVideoDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
