import { Box } from '@mui/material'
import React from 'react'
import Navbar from '../../components/Navbar'
import Sidenav from '../../components/Sidenav';
import CandidateForm from './CandidateForm';
import { useGetUserData } from "./GetData";
import DisplayCandidates from './DisplayCandidates';

const Candidate = () => {
  const { candidates, getUserData } = useGetUserData();
  return (
   <Box>
    <Navbar/>
        <Box sx={{ display: "flex" }}>
            <Sidenav />
            </Box>
            {/* <CandidateForm/> */}
            <DisplayCandidates candidates={candidates} getUserData={getUserData} />
   </Box>
  )
}

export default Candidate
