import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import CandidatesResult from "./pages/CandidatesResult";
import RegisterForm from "./pages/Signup/Signup";
import Login from "./pages/Signin/Signin";
import Users from "./pages/Users/Users";
import SignupRequest from "./pages/Users/SignupRequest";
import InviteCandidate from "./pages/InviteCandidate/InviteCandidate";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/HomePage/Home";
import CompanyuserSignup from "./pages/Signup/CompanyuserSignup";
import SingleUserSignup from "./pages/Signup/SingleUserSignup";
import Candidate from "./pages/InviteCandidate/InviteCandidate";


export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <PrivateRoute>
                {/* <Candidates /> */}
                <Home/>
              </PrivateRoute>
            }
          ></Route>
          
          <Route
            path="/usersData"
            exact
            element={
              <PrivateRoute>
                <SignupRequest />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/signupRequest"
            exact
            element={
              <PrivateRoute>
                <SignupRequest />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/users"
            exact
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/interviewResult"
            exact
            element={
              <PrivateRoute>
                <CandidatesResult />
              </PrivateRoute>
            }
          ></Route>
          <Route path="/register" exact element={<RegisterForm />}></Route>
          <Route path="/companyusersignup" exact element={
          <CompanyuserSignup />
          }></Route>
          <Route path="/singleusersignup" exact element={
            <PrivateRoute>
          <SingleUserSignup />
          </PrivateRoute>
          }></Route>
          <Route path="/login" exact element={<Login />}></Route>
          {/* <Route path="/login/:company" exact element={<Login />}></Route> */}
          <Route path="/inviteCandidate" exact element={<PrivateRoute><InviteCandidate /></PrivateRoute>}></Route>
          <Route path="/inviteCandidate" exact element={<PrivateRoute><Candidate /></PrivateRoute>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
