import { Box, Button, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import Navbar from "../../components/Navbar";
import Sidenav from "../../components/Sidenav";
import UsersNav from "./UsersNav";
import { useNavigate } from "react-router-dom";
import DisplayUsersData from "./Displayusersdata";
import style from "./Users.module.css";


const SignupRequest = () => {
 

  return (
    <>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <Sidenav />
      </Box>
      <Box>
        <h1>Signup Request</h1>
        <UsersNav />
      </Box>
      
      <DisplayUsersData/>
    
    </>
  );
};

export default SignupRequest;
