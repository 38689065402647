import * as React from "react";
import { styled, alpha, makeStyles } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAppStore } from "../appStore";
import { useNavigate, Link } from "react-router-dom";
import style from "./Navbar.module.css";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import niyuktiLogo from "../assets/Niyukti/niyuktiLogo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  ListItemButton,
  TextField,
} from "@mui/material";
import axios from "axios";
// import BASE_URL from "../pages/Config";
import { useState } from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;


const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Navbar() {
  const [user, setUser] = React.useState("");
  const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [profilePopoverAnchorEl, setProfilePopoverAnchorEl] =
    React.useState(null);
  const updateDopen = useAppStore((state) => state.updateDopen);
  const dopen = useAppStore((state) => state.dopen);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const email = localStorage.getItem("email");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // Fetching User Details
  const getSingleUserData = () => {
    axios
      .post(`${BASE_URL}/api/v1/user/getSingleUserDetails`, { email: email })
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const handleResetPassword = () => {
  //   if (newPassword !== confirmPassword) {
  //     setPasswordsMatch(false);
  //   } else {
  //     // Perform password reset logic
  //     setPasswordsMatch(true);
  //     handleResetDialogClose();
  //   }
  // };
  const handleToggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  React.useEffect(() => {
    getSingleUserData();
  }, []);
  const handleResetDialogOpen = () => {
    setResetDialogOpen(true);
    handleProfilePopoverClose();
  };

  const handleResetDialogClose = () => {
    setResetDialogOpen(false);
    setOldPassword(""); // Clear the email input
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("domain");
    localStorage.removeItem("token");
    localStorage.removeItem("approvedUsers");
    localStorage.removeItem("candidates");
    localStorage.removeItem("pendingUsers");
    navigate("/login")
    handleMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfilePopoverOpen = (event) => {
    setProfilePopoverAnchorEl(event.currentTarget);
    handleMenuClose();
  };
  const handleProfilePopoverClose = () => {
    setProfilePopoverAnchorEl(null);
  };

  // Reset Password Logic Here
  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      return setPasswordsMatch(false);
    }
    setLoading(true);
    axios
      .patch(`${BASE_URL}/api/v1/user/resetPassword`, {
        email: email,
        newPassword: newPassword,
        oldPassword: oldPassword,
      })
      .then((res) => {
        console.log(res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);

        setNewPassword("");
        setConfirmPassword("");
        handleResetDialogClose();
        setPasswordsMatch(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogMessage(error?.response?.data);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);

        // setNewPassword("");
        // setConfirmPassword("");
      }).finally(() => {
        setLoading(false);
      });
    
  };

  const ResetPasswordDialogId = "reset-password-popover";
  const renderResetPasswordDialog = (
    <Dialog
      id={ResetPasswordDialogId}
      open={resetDialogOpen}
      onClose={handleResetDialogClose}
      aria-labelledby="reset-dialog-title"
    >
      {/* <DialogTitle id="reset-dialog-title">Reset Password</DialogTitle> */}
      <DialogContent style={{ width: "450px" }}>
        <DialogContentText>Old Password</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="oldPassword"
          label="Old Password"
          type={showOldPassword ? "text" : "password"}
          fullWidth
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleOldPasswordVisibility}>
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <DialogContentText>New Password</DialogContentText> */}
        <TextField
          // autoFocus
          margin="dense"
          id="newPassword"
          label="New Password"
          type={showNewPassword ? "text" : "password"}
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleNewPasswordVisibility}>
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <DialogContentText>Confirm New Password</DialogContentText> */}
        <TextField
          // autoFocus
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!passwordsMatch}
          helperText={!passwordsMatch && "Passwords don't match"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResetDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleResetPassword} color="primary" disabled={loading}>
        {loading ? (
                <CircularProgress size={24} /> // Show loader when loading is true
              ) : (
                " Reset"
              )}
        </Button>
      </DialogActions>
    </Dialog>
  );
  const profilePopoverId = "profile-popover";
  const renderProfilePopover = (
    <Popover
      id={profilePopoverId}
      open={Boolean(profilePopoverAnchorEl)}
      anchorEl={profilePopoverAnchorEl}
      onClose={handleProfilePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          width: 300,
          marginTop: -70,
        },
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}
      >
        <CloseIcon
          onClick={handleProfilePopoverClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <List>
        <ListItem>
          <ListItemText
            primary={`Name: ${user?.firstName + " " + user?.lastName}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Email: ${user?.email}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Mobile: ${user?.mobile}`} />
        </ListItem>
        <Divider />
        <ListItemButton
          onClick={handleResetDialogOpen}
          style={{
            backgroundColor: "#007bff",
            color: "#ffffff",
            width: "60%",
            margin: "0 auto",
            display: "block",
            textAlign: "center",
            height: "40px",
            borderRadius: "5px",
            marginTop: "4px",
          }}
        >
          <ListItemText primary="Reset Password" />
        </ListItemButton>
      </List>
    </Popover>
  );

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          width: 150,
          marginTop: 45,
        },
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handleProfilePopoverOpen}>
        <p>Profile</p>
      </MenuItem>

      <MenuItem onClick={handleLogout}>
        <Link style={{ textDecoration: "none", color: "grey" }} to="/login">
          Logout
        </Link>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ width: 240, marginTop: 16 }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#ffffff",
          color: "#2f2f2f",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {
              updateDopen(!dopen);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <img
              src={niyuktiLogo}
              alt="Niyukti Logo"
              style={{ height: "32px", marginTop: "10px" }}
            />
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderProfilePopover}
      {renderResetPasswordDialog}

       {/* SignUp Dialog Box */}
       <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
