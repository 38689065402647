import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import style from "./Users.module.css";


const UsersNav = () => {
  return (
    <Box className={style.usersNavbar}>
       <Box ><Link className={style.usersLink} to="/signupRequest">Signup Request</Link></Box>
       <Box ><Link className={style.usersLink} to="/users">Users</Link></Box>
    </Box>
  )
}

export default UsersNav