import { useEffect, useState } from "react";
import axios from "axios";
// import BASE_URL from "../Config";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const useGetUserData = () => {
  const [candidates, setCandidates] = useState([]);
  let domain = localStorage.getItem("domain")
  const getUserData = () => {
    axios
      .get(`${BASE_URL}/api/v1/user/getCandidates/`+domain)
      .then((res) => {
        setCandidates(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return { candidates, getUserData };
};
