import React, { useEffect, useRef, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ReCAPTCHA from "react-google-recaptcha";
import {
  TextField,
  Button,
  Box,
  Stack,
  Checkbox,
  InputAdornment,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Signup.module.css";
import PeopleIcon from "@mui/icons-material/People";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import DomainIcon from "@mui/icons-material/Domain";
import zIndex from "@mui/material/styles/zIndex";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Site_Key = process.env.REACT_APP_Site_Key;

const initData = {
  firstName: "",
  lastName: "",
  mobile: "",
  domainType: "",
  domain: "",
  email: "",
  password: "",
};

const RegisterForm = () => {
  const [userData, setUserData] = useState({
    initData,
    isValidPassword: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const { firstName, lastName, mobile, domainType, domain, email, password } =
    userData;
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [captcha, setCaptcha] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [signupEmailError, setSignupEmailError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });

    if (value === "") {
      if (name === "password") {
        setUserData((prevData) => ({ ...prevData, isValidPassword: false }));
      }
    } else if (name === "password") {
      const isValidPassword =
        value.length >= 8 &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        );

      // Update the userData object with the password validation result
      setUserData((prevData) => ({ ...prevData, isValidPassword }));
    }
    if (value === "") {
      if (name === "email") {
        setSignupEmailError(false);
      }
    } else if (name === "email") {
      // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; 
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.(com|net|org|edu|gov|in|mil|ca|uk|au|gov\.uk|co\.jp)$/i;
      const inputValue = e.target.value;
    
      if (!emailRegex.test(inputValue)) {
        setSignupEmailError(true);
      } else {
        setSignupEmailError(false);
      }
    
      setUserData((prevData) => ({ ...prevData, inputValue }));
    }
    
    if (value === "") {
      if (name === "mobile") {
        setMobileError(false);
      }
    } else if (name === "mobile") {
      const phoneRegex = /^\d{10}$/; // Example: 10-digit phone number
      const isValidMobile = phoneRegex.test(value);
      setMobileError(!isValidMobile);
      setUserData((prevData) => ({ ...prevData, mobile: value }));
    }

    if (name === "domain" && domainType === "niyukti") {
      const subdomainSuggestions = await fetchSuggestions(value);
      setSuggestions(subdomainSuggestions);
    }
  };

  const fetchSuggestions = async (subdomain) => {
    const Domain = subdomain + ".niyukti.ai";
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/user/getDomain?domain=${Domain}`
      );

      if (!response.data.available) {
        return [`Sorry, ${Domain} is not available.`];
      }

      return [`${response.data.suggestions} Available`] || [];
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      return [];
    }
  };
  function handleRecaptchaChange() {
    setCaptcha(true);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (signupEmailError) {
      setDialogMessage("Please Enter Valid Email");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    if (mobileError) {
      setDialogMessage("Please Enter Valid Mobile Number");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/v1/user/register`, userData)
      .then((res) => {
        console.log(res);
        // alert(res.data.message);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
          navigate(`/login`);
        }, 3000);

        setUserData(initData);
      })
      .catch((error) => {
        console.log("error", error);
        // alert(error.response.data);
        setDialogMessage(error?.response?.data);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);

        // setUserData(initData);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
     {(password && password.length > 0 && !userData.isValidPassword) || (signupEmailError && mobileError && (password && password.length > 0 && !userData.isValidPassword)) || (signupEmailError && mobileError && (password && password.length > 0 && !userData.isValidPassword) && (domainType === "custom")) || (domainType === "custom") || (domainType === "niyukti") || (signupEmailError && mobileError && (password && password.length > 0 && !userData.isValidPassword) && (domainType === "niyukti")) ?( <Box sx={{ display: "flex" }}>
        <form
          className={styles.signup}
          onSubmit={handleSubmit}
          action={<Link to="/login" />}
        >
          <h2>Add User</h2>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 5 }}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="First Name"
              onChange={handleChange}
              name="firstName"
              value={firstName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Last Name"
              onChange={handleChange}
              name="lastName"
              value={lastName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <TextField
            type="mobile"
            variant="outlined"
            color="secondary"
            label="Mobile"
            onChange={handleChange}
            value={mobile}
            name="mobile"
            fullWidth
            required
            sx={{ mb: 5, backgroundColor: "rgb(250, 245, 245)" }}
            error={mobileError}
            helperText={mobileError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid mobile number</p> : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="email"
            variant="outlined"
            color="secondary"
            label="Email"
            onChange={handleChange}
            value={email}
            name="email"
            fullWidth
            required
            sx={{ mb: 5, backgroundColor: "rgb(250, 245, 245)" }}
            inputProps={{ autoComplete: "new-email" }}
            error={signupEmailError}
            helperText={
              signupEmailError ?<p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid email address</p>: ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        {password && password.length > 0 && !userData.isValidPassword?(<TextField
            type={showPassword ? "text" : "password"}
            variant="outlined"
            color="secondary"
            label="Password"
            onChange={handleChange}
            value={password}
            name="password"
            required
            fullWidth
            sx={{ mb: 8, backgroundColor: "rgb(250, 245, 245)" }}
            error={password && password.length > 0 && !userData.isValidPassword} // Highlight if password is not valid
            helperText={
              password && password.length > 0 && !userData.isValidPassword ? (
                <div style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"4.8vw"}}>
                  Password must be at least 8 characters long
                  and contain a combination of:
                  <br />
                  - Uppercase and lowercase letters
                  - At least one numeric digit (0-9)
                  <br />
                  - At least one special character
                </div>
              ) : null
            }
            InputProps={{
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />):<TextField
          type={showPassword ? "text" : "password"}
          variant="outlined"
          color="secondary"
          label="Password"
          onChange={handleChange}
          value={password}
          name="password"
          required
          fullWidth
          sx={{ mb: 5, backgroundColor: "rgb(250, 245, 245)" }}
          error={password && password.length > 0 && !userData.isValidPassword} // Highlight if password is not valid
          helperText={
            password && password.length > 0 && !userData.isValidPassword ? (
              <div style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"4.5vw"}}>
                Password must be at least 8 characters long
                and contain a combination of:
                <br />
                - Uppercase and lowercase letters
                - At least one numeric digit (0-9)
                <br />
                - At least one special character
              </div>
            ) : null
          }
          InputProps={{
            autoComplete: "new-password",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />}
          

          <RadioGroup
            aria-label="domainType"
            name="domainType"
            value={domainType}
            onChange={handleChange}
            sx={{ flexDirection: "column", marginBottom: 2 }}
          >
            <FormControlLabel
              value="custom"
              control={<Radio color="primary" />}
              label="Do you want custom domain"
            />
            <FormControlLabel
              value="niyukti"
              control={<Radio color="primary" />}
              label="Do you want niyukti's sub-domain"
            />
          </RadioGroup>

          {/* Render domain-specific input fields */}
          {domainType === "custom" && (
            <TextField
              type="domain"
              variant="outlined"
              color="secondary"
              label="Domain Name"
              onChange={handleChange}
              value={domain}
              name="domain"
              fullWidth
              required
              sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DomainIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {domainType === "niyukti" && (
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Name"
              onChange={handleChange}
              value={domain}
              name="domain"
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      variant="body1"
                      component="span"
                      style={{ color: "#000000" }} // Set the color to black
                    >
                      .niyukti.ai
                    </Typography>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <DomainIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
            />
          )}
          {domainType === "niyukti" && domain && suggestions.length > 0 && (
            <ul style={{ listStyleType: "none", marginTop: "-15px" }}>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  style={{
                    color: suggestion.includes("not available")
                      ? "red"
                      : "green",
                  }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          <ReCAPTCHA sitekey={Site_Key} onChange={handleRecaptchaChange} />
          <Box className={styles.signupRow}>
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={!captcha || loading}
              style={{
                backgroundColor: captcha ? "#1A1A57" : "white",
                color: captcha ? "white" : "grey",
                height: "3vw",
              }}
            >
              {loading ? (
                <CircularProgress size={24} /> // Show loader when loading is true
              ) : (
                " Register"
              )}
            </Button>
            <p>
              Already have an account?{" "}
              <a style={{ textDecoration: "none" }} href="/login">
                Login
              </a>
            </p>
          </Box>
        </form>
      </Box>):( <Box sx={{ display: "flex" }}>
        <form
          className={styles.signup1}
          onSubmit={handleSubmit}
          action={<Link to="/login" />}
        >
          <h2>Add User</h2>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="First Name"
              onChange={handleChange}
              name="firstName"
              value={firstName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Last Name"
              onChange={handleChange}
              name="lastName"
              value={lastName}
              fullWidth
              required
              sx={{ backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <TextField
            type="mobile"
            variant="outlined"
            color="secondary"
            label="Mobile"
            onChange={handleChange}
            value={mobile}
            name="mobile"
            fullWidth
            required
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            error={mobileError}
            helperText={mobileError ? <p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid mobile number</p> : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="email"
            variant="outlined"
            color="secondary"
            label="Email"
            onChange={handleChange}
            value={email}
            name="email"
            fullWidth
            required
            sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
            inputProps={{ autoComplete: "new-email" }}
            error={signupEmailError}
            helperText={
              signupEmailError ?<p style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"3.8vw",left:"9vw"}}>Please enter a valid email address</p>: ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        {password && password.length > 0 && !userData.isValidPassword?(<TextField
            type={showPassword ? "text" : "password"}
            variant="outlined"
            color="secondary"
            label="Password"
            onChange={handleChange}
            value={password}
            name="password"
            required
            fullWidth
            sx={{ mb: 5.5, backgroundColor: "rgb(250, 245, 245)" }}
            error={password && password.length > 0 && !userData.isValidPassword} // Highlight if password is not valid
            helperText={
              password && password.length > 0 && !userData.isValidPassword ? (
                <div style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"4.5vw"}}>
                  Password must be at least 8 characters long
                  and contain a combination of:
                  <br />
                  - Uppercase and lowercase letters
                  - At least one numeric digit (0-9)
                  <br />
                  - At least one special character
                </div>
              ) : null
            }
            InputProps={{
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />):<TextField
          type={showPassword ? "text" : "password"}
          variant="outlined"
          color="secondary"
          label="Password"
          onChange={handleChange}
          value={password}
          name="password"
          required
          fullWidth
          sx={{ mb: 4, backgroundColor: "rgb(250, 245, 245)" }}
          error={password && password.length > 0 && !userData.isValidPassword} // Highlight if password is not valid
          helperText={
            password && password.length > 0 && !userData.isValidPassword ? (
              <div style={{backgroundColor:"white",position:"absolute",zIndex:"2",top:"4.5vw"}}>
                Password must be at least 8 characters long
                and contain a combination of:
                <br />
                - Uppercase and lowercase letters
                - At least one numeric digit (0-9)
                <br />
                - At least one special character
              </div>
            ) : null
          }
          InputProps={{
            autoComplete: "new-password",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />}
          

          <RadioGroup
            aria-label="domainType"
            name="domainType"
            value={domainType}
            onChange={handleChange}
            sx={{ flexDirection: "column", marginBottom: 2 }}
          >
            <FormControlLabel
              value="custom"
              control={<Radio color="primary" />}
              label="Do you want custom domain"
            />
            <FormControlLabel
              value="niyukti"
              control={<Radio color="primary" />}
              label="Do you want niyukti's sub-domain"
            />
          </RadioGroup>

          {/* Render domain-specific input fields */}
          {domainType === "custom" && (
            <TextField
              type="domain"
              variant="outlined"
              color="secondary"
              label="Domain Name"
              onChange={handleChange}
              value={domain}
              name="domain"
              fullWidth
              required
              sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DomainIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {domainType === "niyukti" && (
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Name"
              onChange={handleChange}
              value={domain}
              name="domain"
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      variant="body1"
                      component="span"
                      style={{ color: "#000000" }} // Set the color to black
                    >
                      .niyukti.ai
                    </Typography>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <DomainIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
            />
          )}
          {domainType === "niyukti" && domain && suggestions.length > 0 && (
            <ul style={{ listStyleType: "none", marginTop: "-30px" }}>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  style={{
                    color: suggestion.includes("not available")
                      ? "red"
                      : "green",
                  }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          <ReCAPTCHA sitekey={Site_Key} onChange={handleRecaptchaChange} />
          <Box className={styles.signupRow}>
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={!captcha || loading}
              style={{
                backgroundColor: captcha ? "#1A1A57" : "white",
                color: captcha ? "white" : "grey",
                height: "3vw",
              }}
            >
              {loading ? (
                <CircularProgress size={24} /> // Show loader when loading is true
              ) : (
                " Register"
              )}
            </Button>
            <p>
              Already have an account?{" "}
              <a style={{ textDecoration: "none" }} href="/login">
                Login
              </a>
            </p>
          </Box>
        </form>
      </Box>)}

      {/* SignUp Dialog Box */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RegisterForm;
